import classNames from 'classnames';
import React from 'react';
import Button from '../Button/Button';
import { Header, Paragraph} from '../Typography/Typography';
import cl from './ContentModal.module.css';



interface ContentModalProps extends React.HTMLAttributes<HTMLDivElement> {
  setModalIsOpen: (isOpen: boolean) => void;
  header: string;
  closeOnConfirm?: boolean;
  bodyText?: string,
  onConfirm: () => void;
  onClose?: () => void;
  textButton?: string;
  closeButton?: string;
  loading?: boolean;
  disabled?: boolean;
  lite?: boolean;
  children?: React.ReactNode;
}

const ContentModal: React.FC<ContentModalProps> = ({
  setModalIsOpen,
  header,
  closeOnConfirm = true,
  bodyText,
  onConfirm,
  onClose,
  textButton = 'OK',
  closeButton = 'Cancel',
  loading = false,
  disabled = false,
  children,
  lite = false,
  ...props
}) => {
  const onClick = () => {
    if (closeOnConfirm) setModalIsOpen(false);
    onConfirm();
  };

  const onCancel = () => {
    setModalIsOpen(false);
    if (onClose) onClose();
  };

  return (
    <div className={cl.container} {...props}>
      <Header>{header}</Header>
      {bodyText && <Paragraph className={cl.bodyText}>{bodyText}</Paragraph>}
      {children}
      <div className={cl.buttons}>
        {!lite && <Button secondary onClick={onCancel}>{closeButton}</Button>}
        <Button disabled={disabled || loading} loading={loading} onClick={onClick}>{textButton}</Button>
      </div>
    </div>
  );
};

export default ContentModal;
