import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { setInfo } from '../../redux/slices/UserInfoSlice'
import { cleanError, logining } from '../../redux/slices/LoginSlice'
import { setPurchaseNotice } from '../../redux/slices/PurchaseNoticeSlice'

import { useWindowWidth } from './../../hooks/useWindowWidth';
import { openMainDB } from '../../db/mainDB'
import SocialsLogin from '../login/SocialsLogin';

import { Caption } from './../../UI/Typography/Typography';
import Input from '../../UI/input/Input'
import Button from '../../UI/Button/Button'

import cl from '../../styles/components/forms/LoginForm.module.css'

function LoginForm({ setBigError = () => { }, socAuthLoading, setSocAuthLoading, ...props }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const winWidth = useWindowWidth()

	const userInfoState = useSelector((state) => state.userInfo)
	const { loadingInfo, errorInfo } = userInfoState


	const location = useLocation();

	function getQueryParam(param) {
		return new URLSearchParams(location.search).get(param);
	}

	const referrer = getQueryParam('referrer');
	const selectedModuls = getQueryParam('selectedmoduls');
	const quantityModuls = getQueryParam('quantitymoduls');
	const videoModule = getQueryParam('videomodule');
	const flexBuild = getQueryParam('flexBuild');

	useEffect(() => {
		dispatch(cleanError())
		const token = window.localStorage.getItem('token')
		const id = window.localStorage.getItem('id')
		if (token !== undefined && token !== null && id !== undefined && id !== null) {
			//onFetchUserInfo(token, id)
			if (flexBuild) {
				navigate('/home/subscription/flex')
			} else {
				navigate('/home/vr')
			}
			//onCheckToken(token, id)
		}
	}, [])

	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [errInputLogin, setErrInputLogin] = useState('')
	const [errInputPassword, setErrInputPassword] = useState('')

	const cleanErr = () => {
		setErrInputLogin('')
		setErrInputPassword('')
	}

	const loginState = useSelector((state) => state.login)
	const { loading, error } = loginState

	const loginHandler = async () => {
		const res = await dispatch(logining({ login, password }))
		if ((res.payload.status === 200 || res.payload.status === 'success') && res.payload.data) {
			await openMainDB()
			await dispatch(setInfo(res.payload))
			if (selectedModuls && quantityModuls) {
				dispatch(setPurchaseNotice({ comID: res.payload.company, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
			}
			if (winWidth < 768) {
				navigate('/home/vr')
			} else {
				navigate('/home/vr')
			}


		}
		if (res.payload === 400 || res.payload === 'error') {
			setErrInputPassword('Something went wrong')
			toast.error('Something went wrong')
		} else if (res.payload === 403) {
			setErrInputPassword('Wrong login or password')
			toast.error('Wrong login or password')
		} else if (res.payload === 404) {
			setErrInputPassword('User not found')
			toast.error('User not found')
		} else if (res.payload === 500) {
			setErrInputPassword('Something went wrong')
			toast.error('Something went wrong')
		} else if (res.payload === 401) {
			setBigError("You're using a different authentication method. Please try again with your registered method or a verification code.")
		} else if (res.payload === 402) {
			navigate('/user-deactivated')
		}

	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (login !== '' && password !== '') {
			cleanErr()
			loginHandler()
		} else if (login === '' && password === '') {
			setErrInputLogin('Login is required')
			setErrInputPassword('Password is required')
		} else if (login === '') {
			setErrInputPassword('')
			setLogin('')
			setErrInputLogin('Login is empty')
		} else if (password === '') {
			setErrInputLogin('')
			setPassword('')
			setErrInputPassword('Password is empty')
		}
	}

	const onForgot = (e) => {
		e.preventDefault()
		navigate('/changepassword')
	}

	const onBack = (e) => {
		e.preventDefault()
		if (referrer === 'deam') {
			window.open('https://www.digitalengineeringmagic.com/', '_self', '')
		} else if (referrer === 'metaenga') {
			window.open('https://metaenga.com/', '_self', '')
		}
	}

	const onSocialSuccessLogin = (data) => {
		localStorage.setItem('token', data.token)
		localStorage.setItem('email', data.email)
		localStorage.setItem('id', data.id)
		if (selectedModuls && quantityModuls) {
			dispatch(setPurchaseNotice({ comID: data.companyId, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
		}

		openMainDB()
		if (data.type === 'login') {
			navigate('/home/vr')
		} else {
			navigate('/home/vr?hello=true')
		}
	}

	return (
		<>
			<form className={cl.form}>
				<div className={cl.input}>
					<Input
						disabled={loading || loadingInfo}
						value={login}
						onChange={(e) => setLogin(e.target.value)}
						label='Email'
						err={errInputLogin || error}
						helperText={errInputLogin} />
				</div>
				<div className={cl.input}>
					<Input
						disabled={loading || loadingInfo}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type='password'
						label='Password'
						err={errInputPassword || error}
						helperText={errInputPassword} />
				</div>
				<div className={cl.forgot}>
					<a href='#' onClick={onForgot} >Forgot password?</a>
				</div>
				<div className={cl.button}>
					{referrer && <Button onClick={onBack} secondary>Back</Button>}
					<Button onClick={onSubmit} loading={loading || loadingInfo} disabled={socAuthLoading}>Log in</Button>
					<Caption style={{ fontSize: 16 }}>or</Caption>
				</div>

			</form>
			<SocialsLogin
				onSocialSuccessLogin={onSocialSuccessLogin}
				socAuthLoading={socAuthLoading}
				setSocAuthLoading={setSocAuthLoading}
				setBigError={setBigError}
			/>
		</>
	)
}

export default LoginForm