import classNames from 'classnames';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Paragraph } from '../../Typography/Typography';
import cl from '../accordion/AccordionItem.module.css';

interface BaseProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  iconClass?: string;
  inside?: boolean;
  path?: string; 
}

type MenuLinkNavProps = BaseProps & Omit<NavLinkProps, 'title' | 'children' | 'to'>;

const MenuLink: React.FC<MenuLinkNavProps> = React.memo(
  ({ title, iconClass = '', path, inside, children, className, ...props }) => (
    path ? (
      <NavLink
        className={({ isActive }) =>
          isActive ? classNames(cl.title, cl.active, className) : classNames(cl.title, className)
        }
        to={path} 
        style={inside ? { paddingLeft: 64 } : {}}
        {...props}
      >
        {iconClass && <i className={iconClass} />}
        <Paragraph>{title}</Paragraph>
        {children}
      </NavLink>
    ) : (
      <div className={classNames(cl.title, className)} {...props}>
        {iconClass && <i className={iconClass} />}
        <Paragraph>{title}</Paragraph>
        {children}
      </div>
    )
  )
);

export default MenuLink;
