/* global FB */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'

import { loginRequest } from '../../msalConfig'
import { onLoginByGoogle } from '../../redux/slices/LoginSlice'

import GoogleBtn from '../../UI/googleBtn/GoogleBtn'
import FbBtn from '../../UI/fbBtn/FbBtn'
import SpinnerOverlay from '../../UI/spinner/SpinnerOverlay'
import { TextError } from '../../UI/Typography/Typography'

function SocialsLogin({ onSocialSuccessLogin, socAuthLoading, setSocAuthLoading, setBigError, ...props }) {
	const dispatch = useDispatch()

	const [errSocLogin, setErrSocLogin] = useState(false)


	const sendGoogleToken = async (response) => {
		setErrSocLogin(false)
		setSocAuthLoading(true)
		const res = await dispatch(onLoginByGoogle(response.access_token))
		if (res.payload.status === 200 || res.payload.status === 'success') {
			onSocialSuccessLogin(res.payload)
			setSocAuthLoading(false)
		} else {
			setErrSocLogin(true)
			setSocAuthLoading(false)
		}
	}

	const googleLogin = useGoogleLogin({
		onSuccess: async tokenResponse => {
			await sendGoogleToken(tokenResponse)
		},
		onError: (err) => {
			toast.error('Failed to login')
		},
		onNonOAuthError: (err) => {
			setSocAuthLoading(false)
		}
	});

	const onGoogleLogin = () => {
		setSocAuthLoading(true)
		setErrSocLogin(false)
		googleLogin()
	}

	const loadFacebookSDK = () => {
		return new Promise((resolve, reject) => {
			window.fbAsyncInit = function () {
				FB.init({
					appId: '1630968434123066',
					cookie: true,
					xfbml: true,
					version: 'v21.0'
				});
				resolve();
			};

			const script = document.createElement('script');
			script.src = "https://connect.facebook.net/en_US/sdk.js";
			script.async = true;
			script.defer = true;
			script.onerror = reject;
			document.body.appendChild(script);
		});
	};



	const fbLogin = async () => {
		setSocAuthLoading(true)
		setErrSocLogin(false)
		if (typeof FB === 'undefined') {
			await loadFacebookSDK();
		}

		FB.login((response) => {
			if (response.status === 'connected') {
				FB.api('/me', { fields: 'id, name, email' }, (userInfo) => {
					const userData = {
						name: userInfo.name || '',
						email: userInfo.email,
						id: userInfo.id
					};

					if(!userInfo.email) {
						setBigError('Logging in with this method is unavailable for new users. Please use email or Google instead.')
						setSocAuthLoading(false)
						return
					
					}

					axios.post(process.env.REACT_APP_MAIN_API_URL + '/auth/facebook', userData).then((data) => {
						onSocialSuccessLogin(data.data)
					}).catch((err) => {
						setErrSocLogin(true)
						setSocAuthLoading(false)
					}).finally(() => {
						setSocAuthLoading(false)
					})

					setSocAuthLoading(false)
				});
			} else {
				setSocAuthLoading(false)
				//console.log('Пользователь отменил вход или возникла ошибка при авторизации.');
			}
		}, { scope: 'public_profile,email' });
	};


	const { instance, accounts } = useMsal();

	const microsoftLogin = async () => {
		setErrSocLogin(false)
		setSocAuthLoading(true)
		try {
			const response = await instance.loginPopup(loginRequest);
			const { account, accessToken } = response;

			const userData = {
				name: account.name || '',
				email: account.username,
				token: accessToken,
			};

			if(!account.username) {
				setBigError('Logging in with this method is unavailable for new users. Please use email or Google instead.')
				setSocAuthLoading(false)
				return
			
			}

			axios.post(process.env.REACT_APP_MAIN_API_URL + '/auth/microsoft', userData).then((data) => {
				onSocialSuccessLogin(data.data)
			}).catch((err) => {
				setErrSocLogin(true)
				setSocAuthLoading(false)
			}).finally(() => {
				setSocAuthLoading(false)
			})

		} catch (error) {
			setErrSocLogin(true)
		}
		setSocAuthLoading(false)
	};

	return (
		<>
			<div style={{ position: 'relative', width: 'fit-content' }}>
				<GoogleBtn onClick={onGoogleLogin} />
				{/*<MsBtn onClick={microsoftLogin} />*/}
				<FbBtn onClick={fbLogin} />
				{socAuthLoading && <SpinnerOverlay />}
			</div>
			{errSocLogin && <TextError style={{ textAlign: 'center', maxWidth: 392 }}>We weren’t able to log you in with your external account at this time.  Please try again later.</TextError>}
		</>
	)
}

export default SocialsLogin