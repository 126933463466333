import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

import LoginForm from '../components/forms/LoginForm'
import LoginHeader from '../components/login/LoginHeader'

import Button from '../UI/Button/Button';
import { Paragraph } from '../UI/Typography/Typography';
import AlertNotice from '../UI/alertNotice/AlertNotice';

import cl from '../styles/components/forms/LoginForm.module.css'

function Login() {
	const navigate = useNavigate()


	const [loginWithoutGoogleError, setLoginWithoutGoogleError] = useState('')
	const [socAuthLoading, setSocAuthLoading] = useState(false)

	return (
		<LoginHeader header='Log in'>
			{loginWithoutGoogleError && <AlertNotice style={{ maxWidth: 392, marginTop: 8 }}>{loginWithoutGoogleError}</AlertNotice>}

			<LoginForm
				setBigError={setLoginWithoutGoogleError}
				socAuthLoading={socAuthLoading}
				setSocAuthLoading={setSocAuthLoading}
			/>

			<div className={cl.signin}>
				<Paragraph>Log in with</Paragraph>
				<Button linkbtn onClick={() => navigate('/login-with-code')} disabled={socAuthLoading} >Verification Code</Button>
			</div>
			<div className={cl.signup}>
				<Paragraph>Don't have an account?</Paragraph>
				<Button linkbtn onClick={() => navigate('/self-registration')} >Sign up</Button>
			</div>
			<ToastContainer
				theme='colored'
				position="bottom-right"
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</LoginHeader>
	)
}

export default Login