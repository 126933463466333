import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function PCLivechat() {
	const email = useSelector((state) => state.userInfo.info?.email)
	const [visible, setVisible] = useState(false)

	//import { LiveChatWidget } from '@livechat/widget-react'    установить зависимость
	useEffect(() => {
		setTimeout(() => {
			setVisible(true)
		}, 5000)

		return () => {
			setVisible(false)
		}
	}, [])
	return (
		<></>
		//<LiveChatWidget license="17004831" customerEmail={email} />
	)
}

export default PCLivechat