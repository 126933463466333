import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { CompanyInfoService } from "../../services/CompanyInfoServices";

export const uploadLogo = createAsyncThunk(
	'companyInfo/uploadLogo',
	async (data, { rejectWithValue }) => {
		const { file, id } = data
		const token = localStorage.getItem('token')
		try {
			const response = await CompanyInfoService.uploadCompanyLogo(token, id, file)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const deleteLogo = createAsyncThunk(
	'companyInfo/deleteLogo',
	async (data, { rejectWithValue }) => {
		const id = data
		const token = localStorage.getItem('token')
		try {
			const response = await CompanyInfoService.deleteCompanyLogo(token, id)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const getCompanyInfo = createAsyncThunk(
	'companyInfo/getCompanyInfo',
	async (data, { rejectWithValue }) => {
		const id = data
		const token = localStorage.getItem('token')
		try {
			const response = await CompanyInfoService.getCompanyInfo(token, id)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

export const editInfoCompany = createAsyncThunk(
	'companyInfo/editInfoCompany',
	async (data, { rejectWithValue }) => {
		const { id, data: dataCompany } = data
		const token = localStorage.getItem('token')
		try {
			const response = await CompanyInfoService.editCompanyInfo(token, id, dataCompany)
			return response.data
		} catch (e) {
			return rejectWithValue(e.response.status)
		}
	}
)

const companyInfoAdapter = createEntityAdapter({
	selectId: (companyInfo) => companyInfo.companyName,
})

const companyInfoSlice = createSlice({
	name: 'companyInfo',
	initialState: companyInfoAdapter.getInitialState({
		loading: false,
		error: null,
		info: {},
	}),
	extraReducers: (builder) => {
		builder
			.addCase(uploadLogo.pending, (state) => {
				state.loading = true
				state.info.logo = null
				state.error = null
			})
			.addCase(uploadLogo.fulfilled, (state, action) => {
				if (action.payload.status === 'success') {
					state.info.logo = `${state.info.id}.jpg`
				}
				state.loading = false
			})
			.addCase(uploadLogo.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(getCompanyInfo.pending, (state) => {
				state.loading = true
			})
			.addCase(getCompanyInfo.fulfilled, (state, action) => {
				state.loading = false
				state.info = action.payload.data
			})
			.addCase(getCompanyInfo.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(deleteLogo.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteLogo.fulfilled, (state, action) => {
				state.loading = false
				state.info.logo = '' 
			})
			.addCase(deleteLogo.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(editInfoCompany.pending, (state) => {
				state.loading = true
			})
			.addCase(editInfoCompany.fulfilled, (state, action) => {
				state.loading = false
				state.info = {...state.info, ...action.meta.arg.data}
			})
			.addCase(editInfoCompany.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export default companyInfoSlice.reducer