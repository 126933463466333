import React from 'react'
import { TABLET_WIDTH, useWindowWidth } from './../../../hooks/useWindowWidth';
import { toast } from 'react-toastify'


import Tooltip from '../../../UI/tooltip/Tooltip'
import { Caption } from '../../../UI/Typography/Typography'

import cl from './TableControl.module.css'
import { Checkbox } from 'primereact/checkbox';

function TableControl({ checked, setChecked, setAllChecked = null, icons, setSelectAction, data, verification, mobStyle = {}, pcStyle = {}, ...props }) {

	const winWidth = useWindowWidth();

	const onClick = (action) => {
		if (!verification) {
			return
		}
		if (checked?.length > 0) {
			setSelectAction(action, checked)
		} else {
			toast.warn('Select at least one item')
		}
	}

	const onClickMob = (action) => {
		if (!verification) {
			return
		}
		if (checked?.length > 0) {
			setSelectAction(action, checked)
		} else {
			toast.warn('Select at least one item')
		}
	}

	const handleAllChecked = () => {
		if (setAllChecked) {
			setAllChecked()
			return
		}
		if (checked.length === data.length) {
			setChecked([])
		} else if (checked.length < data.length) {
			setChecked(data)
		} else {
			setChecked([])
		}
	}

	const pcControl = <div className={cl.control} style={pcStyle}>
		<Checkbox
			checked={(checked?.length === data?.length) && data?.length !== 0}
			onClick={handleAllChecked}
			className={(checked?.length > 0 && checked?.length < data?.length) ? 'p-checkbox-indeterminate' : ''}
			disabled={!verification}
		/>
		{checked?.length > 0 ? <div className={cl.checked__num}>{checked?.length}/{data?.length}</div> : <div style={{ width: 16 }}></div>}
		<div className={cl.buttons}>
			{icons?.map((icon) => (
				<React.Fragment key={icon.id}>
					<i data-tip data-for={icon.id} className={icon.icon} style={{ color: (!checked || checked?.length === 0 || !verification) && 'var(--fielddisabled-color)', cursor: !verification && 'not-allowed' }} onClick={() => onClick(icon.action)} />
					<Tooltip black id={icon.id}>{icon.tooltip}</Tooltip>
				</React.Fragment>
			))}

		</div>

	</div>

	const mobileControl = checked?.length > 0 && <div className={cl.control} style={mobStyle}>
		<div className={cl.buttons}>
			{icons?.map((icon) => (
				<div key={icon.id} className={cl.icon} onClick={() => onClickMob(icon.action)}>
					<i className={icon.icon} />
					<Caption>{icon.header}</Caption>
				</div>
			))}

		</div>
	</div>
	return (
		winWidth > TABLET_WIDTH ? pcControl : mobileControl
	)
}

export default TableControl