import React from 'react';
import Select, { StylesConfig, Theme } from 'react-select';
import './MainSelect.css';

interface OptionType {
  label: string;
  value: string | number;
}

interface MainSelectProps {
  options: OptionType[];
  onChange: (selectedOption: OptionType | null) => void;
  defaultValue?: OptionType;
  width?: string;
  disabled?: boolean; // Add the optional disabled prop
}

const MainSelect: React.FC<MainSelectProps> = ({
  options,
  onChange,
  defaultValue = options[0],
  width = '100%',
  disabled = false, // Default value set to false
  ...props
}) => {
  const customStyles: StylesConfig<OptionType, false> = {
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#005DFF' : '#2E3A4B',
      backgroundColor: state.isSelected ? '#fff' : provided.backgroundColor,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
    }),
    control: (provided) => ({
      ...provided,
      width,
    }),
  };

  return (
    <Select
      defaultValue={defaultValue}
      classNamePrefix="select"
      options={options}
      isSearchable={false}
      isDisabled={disabled} // Use the disabled prop
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#005DFF',
        },
      })}
      styles={customStyles}
      onChange={onChange}
      {...props}
    />
  );
};

export default MainSelect;
