import React from 'react';

import icon from '../../assets/icons/logos/google.svg'
import cl from './GoogleBtn.module.css';

interface GoogleBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string; 
}

const GoogleBtn: React.FC<GoogleBtnProps> = ({ text = 'Continue with Google', ...props }) => {
  return (
    <button className={cl.google__btn} {...props}>
      <div className={cl.img}>
        <img src={icon} alt="google logo" />
      </div>
      <span>{text}</span>
    </button>
  );
};

export default GoogleBtn;
