import React from 'react';

import icon from '../../assets/icons/logos/fb.svg'
import cl from '../googleBtn/GoogleBtn.module.css';

interface FbBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string; 
}

const FbBtn: React.FC<FbBtnProps> = ({ text = 'Continue with Facebook', ...props }) => {
  return (
		<button className={cl.google__btn} {...props}>
			<div className={cl.img}>
				<img src={icon} alt="Facebook logo" />
			</div>
			<span>{text}</span>
		</button>
  );
};

export default FbBtn;
