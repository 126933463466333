import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import { changePass } from '../redux/slices/UserSettingsSlice'

import SendCode from '../components/forms/SendCode'
import LoginHeader from '../components/login/LoginHeader'
import UserInfo from '../components/login/UserInfo'
import PasswordForm from './../components/forms/PasswordForm';
import ContentModal from '../UI/modal/ContentModal.tsx'

import Modal from '../UI/modal/Modal'

function ChangePassword() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const settingsState = useSelector((state) => state.settings)
	const { loadingSettings, errorSettings } = settingsState

	const [sendNewPass, setSendNewPass] = useState(false)
	const [info, setInfo] = useState(null)
	const [password, setPassword] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const changePassword = async () => {
		const res = await dispatch(changePass({ email: info.email, code: info.code, password }))
		if (res.payload.status === 'success' || res.payload.status === 200) {
			setModalIsOpen(true)
		} else if(res.payload === 400) {
			toast.error('Something went wrong')
		} else if(res.payload === 403) {
			toast.error('Wrong code')
		} else if(res.payload === 404) {
			toast.error('Email not found')
		}
	}
	return (
		<LoginHeader header='Change password'>
			{sendNewPass
				? <>
					<UserInfo info={info} />
					<PasswordForm
						regInfo={info}
						password={password}
						setPassword={setPassword}
						onSend={changePassword}
						sending={loadingSettings}
						button='Change' />
					<Modal
						modalIsOpen={modalIsOpen}
						setIsOpen={setModalIsOpen}
						callback={() => navigate('/')}>
						<ContentModal
							header='Password changed'
							bodyText="Your password has been successfully updated." 
							setModalIsOpen={setModalIsOpen}
							onConfirm={() => navigate('/')}
							textButton="Got it"
							lite={true} >
							
							
							
						</ContentModal>
					</Modal>
				</>
				: <SendCode setInfo={setInfo} setSendNewPass={setSendNewPass} />
			}
			<ToastContainer
				theme='colored'
				position="bottom-right"
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</LoginHeader>
	)
}

export default ChangePassword